



























import Vue from 'vue';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import TourCard from '@/components/Mobile/TourCard.vue';
import InfiniteHits from '@/components/Mobile/InfiniteHits.vue';
import algoliasearch from 'algoliasearch/lite';
import { history } from 'instantsearch.js/es/lib/routers';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import { mapGetters } from 'vuex';
export default Vue.extend({
  components: { Scaffold, TourCard, InfiniteHits },
  mounted() {
    this.$store.dispatch('tour/bindToursRef');
    this.$store.dispatch('tour/fetchUserSavedTourIds');
  },
  data() {
    return {
      showFilterModal: false,
      searchClient: algoliasearch(
        process.env.VUE_APP_ALGOLIA_APP_ID,
        process.env.VUE_APP_ALGOLIA_API_KEY
      ),
      startRefinement: {
        startDateTime: {
          '<': [(Date.now() - 12 * 60 * 60 * 1000) / 1000],
        },
      },
      routing: {
        router: history(),
        stateMapping: simple(),
      },
      filterActive: false,
    };
  },
  computed: {
    ...mapGetters({
      savedTourIds: 'tour/getSavedTourIds',
    }),
    algoliaIndexName() {
      return process.env.VUE_APP_ALGOLIA_TOUR_INDEX_ARCHIVE;
    },
  },
  methods: {
    onFilterActive(val: boolean) {
      this.filterActive = val;
    },
  },
});
